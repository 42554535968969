const getInitialvalues = (filters) => {
  return {
    reference_id: filters.reference_id || [],
    reference_type: filters.reference_type || "",
    project_id: filters.project_id || [],
    customer_id: filters.customer_id || [],
    project_customer_status_id: filters.project_customer_status_id || [],
    user_id: filters.user_id || [],
    intake_id: filters.intake_id || [],
    university_id: filters.university_id || [],
    branch_id: filters.branch_id || [],
    source: filters.source || "",
    followup_date_from: filters.followup_date_from || null,
    followup_date_to: filters.followup_date_to || null,
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
  };
};

export default getInitialvalues;
