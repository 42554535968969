import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import { deletePayment } from "features/invoices/invoices/api/invoices";
import useFormatter from "hooks/useFormatter";
import moment from "moment";
import { toast } from "react-toastify";

const PaymentTable = ({
  loading,
  allPayment,
  pagination,
  setPagination,
  setReload,
  setAddPayment,
  setCurrPayment,
  setAllPayment,
}) => {
  const rows = allPayment;
  const { formatDate } = useFormatter();
  const columns = [
    { id: "payment_date", label: "Pay Date", style: { width: "120px" } },
    {
      id: "payment_method_name",
      label: "Pay Method",
      style: { width: "120px" },
    },
    { id: "note", label: "Note" },
    { id: "transaction_id", label: "TrxID", style: { width: "140px" } },
    {
      id: "amount",
      label: "Amount",
      align: "right",
      style: { width: "120px" },
    },
    {
      id: "action",
      label: "Actions",
      style: { width: "100px" },
      align: "center",
    },
  ];

  const handleDelete = async (id) => {
    try {
      const result = await deletePayment({ id });
      if (result.success) {
        toast.success(result.message);
        setAllPayment(allPayment.filter((task) => task.id !== id));
      } else {
        toast.error(result.message);
      }
    } catch {}
  };

  const handleEditBtn = (value) => {
    setCurrPayment(value);
    setAddPayment(true);
  };

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{
            height: "calc(100vh - 256px)",
            overflowX: "auto",
            ml: "-20px",
            mr: "-20px",
            width: "calc(100% + 40px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "payment_date" ? (
                            formatDate(value)
                          ) : column.id === "amount" ? (
                            `${row?.symbol} ${value}`
                          ) : column.id === "action" ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton onClick={() => handleEditBtn(row)}>
                                <Edit />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(row.id)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          ) : (
                            value || "-"
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
      <Box
        sx={{
          ml: "-20px",
          mr: "-20px",
          width: "calc(100% + 40px)",
          mb: "-20px",
        }}
      >
        <TablePagination
          span={columns?.length}
          setReload={setReload}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Box>
    </>
  );
};

export default PaymentTable;
