import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import PopoverAG from "components/ui/popoverAG";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import moment from "moment";
import { toast } from "react-toastify";

const TaskTable = ({
  loading,
  allTask,
  pagination,
  setPagination,
  setReload,
  setAddTask,
  setCurrTask,
  setAllTask,
}) => {
  const rows = allTask;
  const columns = [
    { id: "name", label: "Task Name", minWidth: 130 },
    { id: "status", label: "Status" },
    { id: "due_date", label: "Due Date", minWidth: 100 },
    { id: "assignees", label: "Assigness", align: "center" },
    { id: "followers", label: "Followers", align: "center" },
    { id: "priority", label: "Priority" },
    { id: "created_by_name", label: "Created" },
    { id: "action", label: "Actions", minWidth: 70, align: "center" },
  ];

  const { fetchData } = useApi();
  const handleDelete = async (id) => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/task/delete/${id}`,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      toast.success(result.message);
      setAllTask(allTask.filter((task) => task.id !== id));
    }
  };

  const handleEditBtn = (value) => {
    setCurrTask(value);
    setAddTask(true);
  };

  const taskpriorities = [
    { id: 1, label: "Low", color: "#008000" },
    { id: 2, label: "Medium", color: "#FFA500" },
    { id: 3, label: "High", color: "#FF0000" },
    { id: 4, label: "Urgent", color: "#800080" },
  ];

  const { base } = useBase();
  const { taskStatuses } = base;
  const getTaskstatus = (statusId) =>
    taskStatuses?.find((p) => p.id === statusId);

  const getTaskPriorities = (priorityId) =>
    taskpriorities?.find((p) => p.id === Number(priorityId));

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 255px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <Box
                              sx={{
                                bgcolor: `${
                                  getTaskstatus(row?.task_status_id)?.color
                                }15`,
                                color: getTaskstatus(row?.task_status_id)
                                  ?.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                                textTransform: "capitalize",
                              }}
                            >
                              {getTaskstatus(row?.task_status_id)?.name}
                            </Box>
                          ) : column.id === "date_added" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "due_date" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "assignees" ||
                            column.id === "followers" ? (
                            <PopoverAG items={value} />
                          ) : column.id === "priority" ? (
                            <Box
                              sx={{
                                bgcolor: `${getTaskPriorities(value)?.color}15`,
                                color: getTaskPriorities(value)?.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {getTaskPriorities(value)?.label}
                            </Box>
                          ) : column.id === "action" ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton onClick={() => handleEditBtn(row)}>
                                <Edit />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(row.id)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 255px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default TaskTable;
