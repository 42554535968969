import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

const MultiSelect = (props) => {
  const [customizedOptions, setCustomizedOptions] = useState([]);
  const variant = props.variant;
  const value = props.value || [];
  const label = props?.label;
  const size = props?.size;
  const onChange = props?.onChange;
  const selectProps = props?.selectProps || { id: "id", label: "label" };
  const options = props?.options || [];
  const isCountry = options[0]?.code;
  const formik = props.formik;
  const name = props.name;
  const disableRemoveOption = props?.disableRemoveOption;

  useEffect(() => {
    if (
      options?.length === value?.length ||
      options?.length === formik?.values?.[name]?.length
    ) {
      setCustomizedOptions([
        { id: "deselect_all", label: "# Deselect All" },
        ...options,
      ]);
    } else {
      setCustomizedOptions([
        { id: "select_all", label: "# Select All" },
        ...options,
      ]);
    }
  }, [value?.length, formik?.values?.[name], options]);

  return (
    <>
      {formik ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="size-small-outlined-multi"
          size="small"
          options={customizedOptions}
          value={customizedOptions.filter((o) =>
            formik?.values?.[name]?.includes(o[selectProps.id])
          )}
          onChange={(event, newValue, reason) => {
            if (reason === "removeOption" && disableRemoveOption) {
              return;
            }
            if (newValue.find((v) => v.id === "select_all")) {
              formik.setFieldValue(
                name,
                options?.map((o) => o[selectProps.id])
              );
            } else if (newValue.find((v) => v.id === "deselect_all")) {
              formik.setFieldValue(name, []);
            } else {
              formik.setFieldValue(
                name,
                newValue.map((nv) => nv[selectProps.id])
              );
            }
          }}
          getOptionLabel={(option) => option.label || option[selectProps.label]}
          renderTags={(value, getTagProps) => (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                pl: "5px",
              }}
            >
              {value[0]?.code && (
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${value[0]?.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${value[0]?.code.toLowerCase()}.png 2x`}
                  alt=""
                />
              )}
              <Typography sx={{ fontSize: "14px" }}>
                {value[0][selectProps.label]?.substring(0, 16)}
                {value.length > 1 && ` & ${value.length - 1}+`}
              </Typography>
            </Box>
          )}
          renderOption={(props, option, { selected }) => (
            <Box
              component="li"
              sx={{
                display: "flex !important",
                justifyContent: "space-between !important",
                alignItems: "center !important",
                "& > img": { mr: 2, flexShrink: 0 },
                "& > button": {
                  backgroundColor: "transparent",
                  border: "0",
                  color: "rgba(0, 0, 0, 0.54)",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#000000",
                  },
                },
              }}
              {...props}
            >
              <div>
                {option.code && (
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                )}
                {option.label || option[selectProps.label]}{" "}
                {option.code && `(${option.code})`}
              </div>

              {disableRemoveOption &&
                !["deselect_all", "select_all"].includes(option.id) &&
                selected && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const newValue = formik.values[name].filter(
                        (o) => o !== option[selectProps.id]
                      );
                      formik.setFieldValue(name, newValue);
                    }}
                  >
                    <CloseIcon />
                  </button>
                )}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant || "outlined"}
              size={size || "small"}
              label={label || "Select"}
              placeholder=""
              error={formik.touched[name] && formik.errors[name]}
              helperText={formik.touched[name] && formik.errors[name]}
            />
          )}
        />
      ) : (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="size-small-outlined-multi"
          size="small"
          options={customizedOptions}
          value={customizedOptions.filter((o) =>
            value.includes(o[selectProps.id])
          )}
          onChange={(event, newValue) => {
            if (newValue.find((v) => v.id === "select_all")) {
              onChange(options?.map((o) => o[selectProps.id]));
            } else if (newValue.find((v) => v.id === "deselect_all")) {
              onChange([]);
            } else {
              onChange(newValue.map((nv) => nv[selectProps.id]));
            }
          }}
          getOptionLabel={(option) => option.label || option[selectProps.label]}
          renderTags={(value, getTagProps) => (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                pl: "5px",
              }}
            >
              {value[0]?.code && (
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${value[0]?.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${value[0]?.code.toLowerCase()}.png 2x`}
                  alt=""
                />
              )}
              <Typography sx={{ fontSize: "14px" }}>
                {value[0][selectProps.label]?.substring(0, 16)}
                {value.length > 1 && ` & ${value.length - 1}+`}
              </Typography>
            </Box>
          )}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.code && (
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
              )}
              {option.label || option[selectProps.label]}{" "}
              {option.code && `(${option.code})`}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant || "outlined"}
              size={size || "small"}
              label={label || "Select"}
              placeholder=""
            />
          )}
        />
      )}
    </>
  );
};

//   return (
//     <>
//       {formik ? (
//         <Autocomplete
//           {...props}
//           disableCloseOnSelect
//           size={size || "small"}
//           multiple
//           id="tags-filled"
//           options={options?.map((option) => option[selectProps.label])}
//           value={options
//             .filter((o) => formik?.values?.[name]?.includes(o[selectProps.id]))
//             .map((d) => d[selectProps.label])}
//           onChange={(e, newValue) => {
//             formik.setFieldValue(
//               name,
//               options
//                 .filter((o) => newValue.includes(o[selectProps.label]))
//                 .map((d) => d[selectProps.id])
//             );
//           }}
//           limitTags={2}
//           renderTags={(value, getTagProps) => (
//             <>
//               <Typography sx={{ fontSize: "14px" }}>
//                 {value.length !== options?.length
//                   ? value[0].substring(0, 8)
//                   : value[0].substring(0, 14)}
//                 {value.length > 1 && ` & ${value.length - 1}+`}
//               </Typography>
//               {value.length !== options?.length && (
//                 <ButtonBase
//                   sx={{
//                     // borderLeft: "1px solid #ddd",
//                     padding: "5px 10px",
//                     marginLeft: "5px",
//                     bgcolor: "#ddd",
//                   }}
//                   onClick={() =>
//                     onChange(options?.map((d) => d[selectProps.id]))
//                   }
//                 >
//                   All
//                 </ButtonBase>
//               )}
//             </>
//           )}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               variant="outlined"
//               size={size || "small"}
//               label={label || "Select"}
//               placeholder=""
//               error={formik.touched[name] && formik.errors[name]}
//               helperText={formik.touched[name] && formik.errors[name]}
//             />
//           )}
//         />
//       ) : (
//         <Autocomplete
//           {...props}
//           disableCloseOnSelect
//           size={size || "small"}
//           multiple
//           id="tags-filled"
//           options={options?.map((option) => option[selectProps.label])}
//           value={options
//             .filter((o) => value.includes(o[selectProps.id]))
//             .map((d) => d[selectProps.label])}
//           onChange={(e, newValue) => {
//             onChange(
//               options
//                 .filter((o) => newValue.includes(o[selectProps.label]))
//                 .map((d) => d[selectProps.id])
//             );
//           }}
//           limitTags={2}
//           renderTags={(value, getTagProps) => (
//             <>
//               <Typography sx={{ fontSize: "14px" }}>
//                 {value.length !== options?.length
//                   ? value[0].substring(0, 8)
//                   : value[0].substring(0, 14)}
//                 {value.length > 1 && ` & ${value.length - 1}+`}
//               </Typography>
//               {value.length !== options?.length && (
//                 <ButtonBase
//                   sx={{
//                     // borderLeft: "1px solid #ddd",
//                     padding: "5px 10px",
//                     marginLeft: "5px",
//                     bgcolor: "#ddd",
//                   }}
//                   onClick={() =>
//                     onChange(options?.map((d) => d[selectProps.id]))
//                   }
//                 >
//                   All
//                 </ButtonBase>
//               )}
//             </>
//           )}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               variant="outlined"
//               size={size || "small"}
//               label={label || "Select"}
//               placeholder=""
//             />
//           )}
//         />
//       )}
//     </>
//   );
// };

export default MultiSelect;
