import { Box, Button, Typography } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import {
  createInvoice,
  updateInvoice,
} from "features/projectCustomers/api/projectCustomer";
import { useFormik } from "formik";
import useApiCall from "hooks/useApiCall";
import useBase from "hooks/useBase";
import moment from "moment";
import { useEffect } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import * as yup from "yup";
import InvoicePriceTable from "./InvoicePriceTable";
const isGivenDateLessThanToday = (givenDate) => {
  const today = moment();
  return moment(givenDate).isBefore(today, "day");
};
let checkePaidAmount = (values) =>
  Number(values.paid_amount) >= Number(values.total) &&
  Number(values.total) !== 0 &&
  Number(values.paid_amount) !== 0;

const AddInvoice = ({
  customer_id,
  project_id,
  setAddInvoice,
  currInvoice,
  setReload,
}) => {
  const { data: updatedResult, loading, fetchData } = useApiCall();
  const { base, updateBase } = useBase();
  const {
    assignees,
    customers,
    intakes,
    projects,
    fees,
    currencies,
    settings,
    references,
    paymentMethods,
    branches,
  } = base;
  const currency_id = settings?.app?.currency_id;
  const currency_symbol = currency_id
    ? currencies?.find((c) => c.id === currency_id)?.symbol
    : "";

  const onSubmit = async (data) => {
    if (currInvoice?.id) {
      fetchData(updateInvoice, {
        body_data: data,
        id: currInvoice?.id,
      });
    } else {
      fetchData(createInvoice, { body_data: data });
    }
  };

  useEffect(() => {
    if (updatedResult && !loading) {
      setReload((pre) => !pre);
      updateBase(["invoices"]);
      setAddInvoice(false);
    }
  }, [updatedResult]);

  const copyMatchingData = (schemaObj, sourceObj) => {
    const obj = {};
    for (const key in schemaObj) {
      if (sourceObj.hasOwnProperty(key)) {
        obj[key] = sourceObj[key];
      }
    }
    return obj;
  };

  // initial values depending on edit mode or create mode
  const initialValues = {
    customer_id: customer_id || "",
    project_id: project_id || "",
    reference_id: currInvoice?.reference_id || "",
    due_date: currInvoice?.due_date || null,
    tax: currInvoice?.tax || 0,
    discount: currInvoice?.discount || 0,
    subtotal: currInvoice?.subtotal || 0,
    total: currInvoice?.total || 0,
    note: currInvoice?.note || "",
    status: currInvoice?.status || "unpaid",
    currency_id: currInvoice?.currency_id || currency_id || "",
    paid_amount: currInvoice?.paid_amount || 0,
    intake_id: currInvoice?.intake_id || "",
    branch_id: currInvoice?.branch_id || "",
    payment_method_id: currInvoice?.payment_method_id || "",
    tags: currInvoice?.tags || [],
    assignees: currInvoice?.assignees?.map((user) => user?.user_id) || [],
    invoice_details:
      currInvoice?.invoice_details?.map((invoice) =>
        copyMatchingData(
          {
            fee_id: 3,
            tax: 10,
            discount: 0,
            subtotal: 1000,
            total: 1010,
          },
          invoice
        )
      ) || [],
  };

  const validationSchema = yup.object({
    customer_id: yup.string("").required("Customer is Required").nullable(),
    currency_id: yup.string("").required("Currency is Required").nullable(),
    assignees: yup.array().required("Assignee is Required").min(1),
    invoice_details: yup
      .array()
      .of(
        yup.object().shape({
          fee_id: yup.string("").required("Fee is Required"),
          subtotal: yup.string("").required("Subtotal is Required"),
        })
      )
      .required("Invoice is Required")
      .min(1),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    formik.dirty &&
      checkePaidAmount(formik.values) &&
      formik.setFieldValue("due_date", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.total, formik.values.paid_amount, formik.dirty]);

  return (
    <Box sx={{ m: "-10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddInvoice(false)}
        >
          View Invoices
        </Button>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 225px)",
          overflowY: "auto",
          pt: "15px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "10px",
            pb: "15px",
          }}
        >
          <Select
            sx={{ gridColumn: "span 3" }}
            name="branch_id"
            formik={formik}
            options={branches}
            label="Branch"
            selectProps={{ id: "id", label: "name" }}
          />
          <Box
            sx={{
              gridColumn: "span 3",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
          >
            <Select
              disabled={customer_id}
              options={customers}
              label="Customer *"
              value={formik?.values?.customer_id}
              onChange={(value) => formik.setFieldValue("customer_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              disabled={project_id}
              options={projects}
              label="Project"
              value={formik?.values?.project_id}
              onChange={(value) => formik.setFieldValue("project_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
          </Box>
          <MultiSelect
            options={assignees}
            label="Assignees *"
            value={formik?.values?.assignees}
            onChange={(value) => formik.setFieldValue("assignees", value)}
            selectProps={{
              id: "user_id",
              label: "name",
            }}
          />
          <Select
            options={references}
            label="Reference"
            name="reference_id"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />
          <Select
            options={intakes}
            label="Intake"
            value={formik?.values?.intake_id}
            onChange={(value) => formik.setFieldValue("intake_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <Select
            name="payment_method_id"
            options={paymentMethods}
            label="Select Payment Method"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />

          <Select
            options={currencies}
            label="Currency *"
            value={formik?.values?.currency_id}
            onChange={(value) => formik.setFieldValue("currency_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />

          <InputField name="paid_amount" label="Paid Amount" formik={formik} />
          {checkePaidAmount(formik.values) ? null : (
            <DateTimePicker
              name="due_date"
              label="Due Date *"
              type="date"
              formik={formik}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
          }}
        >
          <InputField
            sx={{ gridColumn: "span 2" }}
            name="note"
            label="Note"
            multiline
            rows={2}
            formik={formik}
          />
        </Box>
        <Box
          sx={{
            borderTop: "1px dashed #ddd",
            borderBottom: "1px dashed #ddd",
            mt: "15px",
            py: "15px",
          }}
        >
          <InvoicePriceTable formik={formik} fees={fees} />
        </Box>
        <Box>
          <Box
            sx={{
              width: "300px",
              marginLeft: "auto",
              mt: "5px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "100px auto",
              }}
            >
              <Typography align="right">Sub Total</Typography>
              <Typography align="right" sx={{ fontWeight: 700 }}>
                {currency_symbol}
                {formik.values.subtotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "100px auto",
              }}
            >
              <Typography align="right">Tax</Typography>
              <Typography align="right" sx={{ fontWeight: 700 }}>
                {currency_symbol}
                {formik.values.tax}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "100px auto",
              }}
            >
              <Typography align="right">Discount</Typography>
              <Typography align="right" sx={{ fontWeight: 700 }}>
                -{currency_symbol}
                {formik.values.discount}
              </Typography>
            </Box>
            <Box
              sx={{
                borderTop: "1px solid #cbcbcb",
                display: "grid",
                gridTemplateColumns: "100px auto",
                pt: "5px",
                mt: "5px",
              }}
            >
              <Typography align="right" fontSize="16px">
                Total
              </Typography>
              <Typography
                align="right"
                fontSize="16px"
                sx={{ fontWeight: 700 }}
              >
                {currency_symbol}
                {formik.values.total}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: "10px",
        }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={
            !(formik.dirty && formik.isValid) ||
            loading ||
            (checkePaidAmount(formik.values) ? false : !formik.values.due_date)
          }
        >
          {currInvoice?.id ? "Update Invoice" : "Save Invoice"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddInvoice;
