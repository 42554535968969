import { Box, Button } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { viewAllProjectCustomers } from "features/projectCustomers/api/projectCustomer";
import useApiCall from "hooks/useApiCall";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useSelector } from "react-redux";
import AddProject from "./AddProject";
import ProjectTable from "./ProjectTable";

const Project = ({ customer_id }) => {
  const [addProject, setAddProject] = useState(false);
  const [currProject, setCurrProject] = useState({});
  const [allProject, setAllProject] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    let finalPagination = { ...pagination };
    fetchListData(
      viewAllProjectCustomers,
      {
        body_data: {
          customer_id: customer_id ? [customer_id] : [],
        },
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`,
      },
      false
    );
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllProject(allData);
      setPagination(rest);
    }
  }, [data]);

  return (
    <>
      {addProject ? (
        <AddProject
          setAddProject={setAddProject}
          setReload={setReload}
          currProjectId={currProject?.id}
          customer_id={customer_id}
        />
      ) : (
        <Box sx={{ m: "-20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "10px",
            }}
          >
            <SearchBox
              handleSearch={(text) => setSearchText(text)}
              placeholder={"Search in Project.."}
            />
            <Button
              startIcon={<BiPlus />}
              variant="outlined"
              onClick={() => {
                setCurrProject({});
                setAddProject(true);
              }}
            >
              New Project
            </Button>
          </Box>
          <ProjectTable
            currProject={currProject}
            loading={loading}
            allProject={allProject}
            pagination={pagination}
            setPagination={setPagination}
            setReload={setReload}
            setAllProject={setAllProject}
            setAddProject={setAddProject}
            setCurrProject={setCurrProject}
          />
        </Box>
      )}
    </>
  );
};

export default Project;
