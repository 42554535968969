import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import { deleteSponsor } from "features/projectCustomers/api/projectCustomer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SponsorTable = ({
  loading,
  allSponsor,
  setAddSponsor,
  setCurrSponsor,
  setAllSponsor,
}) => {
  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );
  const currencies = useSelector((state) => state?.base?.base_data?.currencies);
  const rows = allSponsor;
  const columns = [
    { id: "note", label: "Sponsor Note", minWidth: 150 },
    { id: "selected_professions_name", label: "Professions", minWidth: 150 },
    { id: "relation", label: "Relation", minWidth: 80 },
    { id: "amount", label: "Amount", align: "right" },
    { id: "source", label: "Source", align: "right" },
    { id: "action", label: "Actions", minWidth: 70, align: "center" },
  ];
  const handleDelete = async (id) => {
    try {
      const result = await deleteSponsor({ id });
      if (result.success) {
        toast.success(result.message);
        setAllSponsor(allSponsor.filter((sponsor) => sponsor.id !== id));
      } else {
        toast.error(result.message);
      }
    } catch {}
  };

  const handleEditBtn = (value) => {
    setCurrSponsor(value);
    setAddSponsor(true);
  };

  const symbol = currency_id
    ? currencies.find((c) => c.id === currency_id).symbol
    : "";

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 256px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.id}
                    style={{
                      backgroundColor: Number(row.alert) ? "#fff1f1" : "",
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "amount" ? (
                            symbol + value
                          ) : column.id === "selected_professions_name" ? (
                            <Typography
                              variant="body2"
                              textTransform="capitalize"
                            >
                              {value?.length ? value?.join(", ") : "-"}
                            </Typography>
                          ) : column.id === "source" ||
                            column.id === "relation" ? (
                            <Typography
                              variant="body2"
                              textTransform="capitalize"
                            >
                              {value}
                            </Typography>
                          ) : column.id === "action" ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton onClick={() => handleEditBtn(row)}>
                                <Edit />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(row.id)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
      {/* <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      /> */}
    </>
  );
};

export default SponsorTable;
