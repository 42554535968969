import { Avatar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ActivitySkeleton from "components/skeletons/ActivitySkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SMSList = ({ profile }) => {
  let [data, setData] = useState([]);
  const { loading, fetchData } = useApi();
  const user = useSelector((state) => state.auth.user_details.user);

  useEffect(() => {
    async function listSMSlist() {
      const endpoint = {
        method: "post",
        url: "/api/admin/sms/list",
        data: {
          lead_id: profile?.id,
          user_id: user?.id,
        },
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        let formatedDataWithUser = result?.data?.map((item) =>
          item.user_id === user?.id
            ? {
                ...item,
                user_image: user?.image,
              }
            : item
        );
        setData(formatedDataWithUser);
      }
    }
    listSMSlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        [...Array(5)].map((_, i) => (
          <Fragment key={i}>
            <ActivitySkeleton />
          </Fragment>
        ))
      ) : (
        <>
          {!!data.length ? (
            <Typography variant="h3" textAlign={"center"}>
              Oops! No SMS Found.
            </Typography>
          ) : (
            data?.map((item, i) => (
              <Box
                key={item?.id}
                sx={{
                  p: "10px",
                  mb: "1.2rem ",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                }}
              >
                <Box
                  variant="outlined"
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{ width: "34px", height: "34px" }}
                      src={UNIVERSAL.BASEURL + item?.user_image}
                    />
                    <Typography variant="h4">{item?.user_name}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "12px",
                        color:
                          item?.status === "Delivered" ? "#00d640" : "#ec1818",
                      }}
                    >
                      {item?.status}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#444",
                      }}
                    >
                      {moment(item?.created_at).format("lll")}
                    </Typography>
                  </Box>
                </Box>
                <Box marginTop={2}>
                  <Typography>{item?.message}</Typography>
                </Box>
              </Box>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default SMSList;
