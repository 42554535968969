import { Box, Button, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import handleError from "utils/handleError";
import * as yup from "yup";
import {
  makeInMillion,
  months,
} from "./viewInvoiceBank/payments/AddEditPayments";

let validationSchema = yup.object({
  account_name: yup.string("").required("Account name is required!"),
  applicant_name: yup.string("").required("Applicant name is required!"),
  bank_invoice_status_id: yup.string("").required().nullable(),
  loan_amount: yup.number().positive().required(),
  interest_amount: yup.number().positive().required(),
  bank_deposit: yup.number().positive().required(),
  payment_interest_amount: yup.number().required("Required Field"),
  month: yup.string("").required().nullable(),
  total_paid: yup.number().required("Required Field"),
});

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllInvoiceBank,
  allInvoiceBank,
  customers,
  references,
  paymentMethods,
  bankInvoiceStatuses,
  bankInvoiceBanks,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const [currInvoiceBank, setCurrInvoiceBank] = useState(
    row_id ? {} : persistedData
  );
  const isUpdating = row_id ? true : false;

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullCustomer = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/bank-invoice/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrInvoiceBank(result?.data);
    };
    row_id && getFullCustomer();
  }, []);

  const onSubmit = async (data) => {
    let endpoint;
    if (isUpdating) {
      endpoint = {
        method: "post",
        url: `/api/admin/bank-invoice/update/${currInvoiceBank?.id}`,
        data: data,
      };
    } else {
      endpoint = {
        method: "post",
        url: `/api/admin/bank-invoice/create`,
        data,
      };
    }

    const result = await fetchData(endpoint);
    if (result?.success) {
      if (isUpdating) {
        setAllInvoiceBank(
          allInvoiceBank.map((item) =>
            item.id === currInvoiceBank.id
              ? { ...currInvoiceBank, ...result.data }
              : item
          )
        );
      } else {
        setAllInvoiceBank([result.data, ...allInvoiceBank]);
      }
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  // initial values depending on edit mode or create mode
  const initialValues = {
    customer_id: currInvoiceBank?.customer_id || null,
    reference_id: currInvoiceBank?.reference_id || null,
    bank_id: currInvoiceBank?.bank_id || null,
    bank_invoice_status_id: currInvoiceBank?.bank_invoice_status_id || null,
    account_name: currInvoiceBank?.account_name || "",
    account_number: currInvoiceBank?.account_number || "",
    applicant_name: currInvoiceBank?.applicant_name || "",
    account_open_date: currInvoiceBank?.account_open_date || "",
    account_close_date: currInvoiceBank?.account_close_date || "",
    followup_date: currInvoiceBank?.followup_date || "",
    note: currInvoiceBank?.note || "",
    bank_deposit: currInvoiceBank?.bank_deposit || "",
    loan_amount: currInvoiceBank?.loan_amount || "",
    interest_amount: currInvoiceBank?.interest_amount || "",
    expense: currInvoiceBank?.expense || "",
    contact_number: currInvoiceBank?.contact_number || "",
    payment_interest_amount: currInvoiceBank?.payment_interest_amount || "",
    month: currInvoiceBank?.month || null,
    total_paid: currInvoiceBank?.total_paid || "",
    payment_method_id: currInvoiceBank?.payment_method_id || "",
    payment_date: currInvoiceBank?.payment_date || null,
    bank_invoice_payment_id: currInvoiceBank?.bank_invoice_payment_id || "",
    bank_invoice_paid_payment_id:
      currInvoiceBank?.bank_invoice_paid_payment_id || "",
    charge:
      makeInMillion({
        amount: currInvoiceBank["payment_interest_amount"],
        loan_amount: currInvoiceBank["loan_amount"],
      }) || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    formik.setFieldValue(
      "charge",
      makeInMillion({
        amount: formik.values.payment_interest_amount,
        loan_amount: formik.values.loan_amount,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.payment_interest_amount, formik.values.loan_amount]);
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: currInvoiceBank?.id
          ? "Edit Bank Invoice"
          : "New Bank Invoice",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box p="20px">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              pb: "15px",
            }}
          >
            <Select
              options={customers}
              label="Customer"
              name="customer_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <InputField
              name="account_number"
              label="Account Number"
              formik={formik}
            />
            <Select
              options={bankInvoiceBanks}
              label="Banks"
              name="bank_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <Select
              options={bankInvoiceStatuses}
              label="Bank Invoice Status *"
              name="bank_invoice_status_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <InputField
              name="applicant_name"
              label="Applicant Name *"
              formik={formik}
            />
            <InputField
              name="account_name"
              label="Account Name *"
              formik={formik}
            />
            <Select
              options={references}
              label="Reference"
              name="reference_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <InputField
              name="bank_deposit"
              label="Bank deposit Amount *"
              type="number"
              formik={formik}
            />
            <InputField
              name="contact_number"
              label="Contact Number"
              formik={formik}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              pb: "15px",
            }}
          >
            <InputField
              name="loan_amount"
              label="Loan Amount *"
              type="number"
              formik={formik}
            />
            <InputField
              name="interest_amount"
              label="Interest Rate *"
              type="number"
              formik={formik}
            />

            <InputField
              name="expense"
              label="Expense"
              type="number"
              formik={formik}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
            }}
          >
            <DateTimePicker
              name="account_open_date"
              label="Account Open Date"
              formik={formik}
            />
            <DateTimePicker
              name="account_close_date"
              label="Account Close Date "
              formik={formik}
            />
            <DateTimePicker
              name="followup_date"
              label="Followup Date "
              formik={formik}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: "10px",
              py: "15px",
            }}
          >
            <InputField
              name="note"
              label="Note"
              multiline
              rows={3}
              formik={formik}
            />
          </Box>

          <Typography
            variant="h4"
            sx={{
              py: "25px",
              textAlign: "center",
            }}
          >
            Create Invoice
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "15px",
              pb: "15px",
            }}
          >
            <Select
              name="month"
              options={months}
              label="Select Months *"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <InputField
              name="payment_interest_amount"
              label="Payment Interest Rate *"
              type="number"
              formik={formik}
            />

            <InputField
              name="charge"
              label="Total Amount"
              type="number"
              formik={formik}
              disabled
            />
          </Box>

          <Typography
            variant="h4"
            sx={{
              py: "25px",
              textAlign: "center",
            }}
          >
            Make Payment
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "15px",
              pb: "15px",
            }}
          >
            <Select
              name="payment_method_id"
              options={paymentMethods}
              label="Select Payment Method"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <InputField
              name="total_paid"
              label="Total Paid *"
              type="number"
              formik={formik}
            />
            <DateTimePicker
              name="payment_date"
              label="Payment Date"
              type="date"
              formik={formik}
            />
          </Box>

          <Button
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
            sx={{ gridColumn: "span 3" }}
          >
            {currInvoiceBank?.id ? "Update Invoice Bank" : "Save Invoice Bank"}
          </Button>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
