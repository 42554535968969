import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";

const TableModal = ({ item, type, open, handleClose,symbol }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      <Box sx={{ width: "580px", height: "350px" }}>
        <Box variant="h5" sx={{ borderBottom: "1px solid #ddd", padding: "10px 20px", fontWeight: "500", mb: "15px" }}>
          {type === "university"
            ? "University Commision"
            : "Semester Commision"}
        </Box>
        <Box sx={{ padding: "0 20px" }}> <Table size="small" aria-label="purchases">
          <TableHead>
            {type === "university" && (
              <TableRow>
                <TableCell sx={{ padding: "7px 15px" }}>Amount</TableCell>
                <TableCell sx={{ padding: "7px 15px" }}>Date</TableCell>
                <TableCell sx={{ padding: "7px 15px" }}>Due Date</TableCell>
              </TableRow>
            )}
            {type === "semester" && (
              <TableRow>
                <TableCell sx={{ padding: "7px 15px" }}>Student Fees Paid Amount</TableCell>
                <TableCell sx={{ padding: "7px 15px" }}>Student Fees Paid Date</TableCell>
                <TableCell sx={{ padding: "7px 15px" }}>Student Fees Due Amount</TableCell>
                <TableCell sx={{ padding: "7px 15px" }}>Student Fees Due Date</TableCell>
                <TableCell sx={{ padding: "7px 15px" }}>Semester</TableCell>
              </TableRow>
            )}
          </TableHead>
          {type === "university" && (
            <TableBody>
              {item?.university_commissions.map((commission) => (
                <TableRow key={commission.university_commission_date}>
                  <TableCell sx={{ padding: "15px" }}>
                    {symbol + commission.university_commission_amount || "-"}
                  </TableCell>
                  <TableCell sx={{ padding: "15px" }} component="th" scope="row">
                    {commission.university_commission_date || "-"}
                  </TableCell>
                  <TableCell sx={{ padding: "15px" }} component="th" scope="row">
                    {commission.university_commission_due_date || "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {type === "semester" && (
            <TableBody>
              {item?.semester_commissions.map((commission) => (
                <TableRow key={commission.student_fees_paid_date}>
                  <TableCell sx={{ padding: "15px" }}>
                    {commission.student_fees_paid_amount || "-"}
                  </TableCell>
                  <TableCell sx={{ padding: "15px" }} component="th" scope="row">
                    {commission.student_fees_paid_date || "-"}
                  </TableCell>
                  <TableCell sx={{ padding: "15px" }}>
                    {commission.student_fees_due_amount || "-"}
                  </TableCell>
                  <TableCell sx={{ padding: "15px" }} component="th" scope="row">
                    {commission.student_fees_due_date || "-"}
                  </TableCell>

                  <TableCell sx={{ padding: "15px" }} component="th" scope="row">
                    {commission.semester.name || "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table></Box>
      </Box>
    </Dialog>
  );
};

export default TableModal;
