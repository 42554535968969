const constants = {
  exam_groups: ["Science", "Arts", "Commerce"],
  exam_names: ["SSC", "HSC", "Bachelor", "Masters", "O level", "A level"],
  exam_boards: [
    "Barisal",
    "Chittagong",
    "Comilla",
    "Dhaka",
    "Jessore",
    "Mymensingh",
    "Rajshahi",
    "Dinajpur",
    "Sylhet",
    "Madrasah Board",
    "Technical Board",
  ],
  grades: [
    {
      id: 1,
      name: "A",
    },
    {
      id: 2,
      name: "B",
    },
    {
      id: 3,
      name: "C",
    },
    {
      id: 4,
      name: "D",
    },
    {
      id: 5,
      name: "E",
    },
  ],
  education_fields: [
    {
      name: "SSC",
      board: "",
      group: "",
      gpa: "",
      passing_year: null,
    },
    {
      name: "HSC",
      board: "",
      group: "",
      gpa: "",
      passing_year: null,
    },
    {
      name: "Bachelor",
      university_id: "",
      subject_id: "",
      gpa: "",
      passing_year: null,
    },
    {
      name: "Master",
      university_id: "",
      subject_id: "",
      gpa: "",
      passing_year: null,
    },
    {
      name: "Diploma",
      group: "",
      subject_id: "",
      board: "",
      gpa: "",
      passing_year: null,
      institute_id: "",
    },
    {
      name: "O Level",
      institute_id: "",
      grade: "",
      passing_year: null,
    },
    {
      name: "A Level",
      institute_id: "",
      grade: "",
      passing_year: null,
    },
  ],
  customer_status: [
    {
      id: "Active",
      label: "Active",
      color: "#0db30d",
    },
    {
      id: "Inactive",
      label: "Inactive",
      color: "#ff564a",
    },
  ],
};

export const {
  exam_groups,
  exam_names,
  exam_boards,
  grades,
  education_fields,
  customer_status,
} = constants;
