const getInitialvalues = (filters) => {
  return {
    project_id: filters.project_id || [],
    customer_id: filters.customer_id || [],
    project_customer_status_id: filters.project_customer_status_id || [],
    user_id: filters.user_id || [],
    intake_id: filters.intake_id || [],
    followup_date_from: filters.followup_date_from || null,
    followup_date_to: filters.followup_date_to || null,
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
  };
};

export default getInitialvalues;
