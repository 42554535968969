import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import PopoverAG from "components/ui/popoverAG";
import { deleteInvoice } from "features/projectCustomers/api/projectCustomer";
import moment from "moment";
import { useState } from "react";
import { BsChevronExpand } from "react-icons/bs";
import { MdOutlinePayment } from "react-icons/md";
import { toast } from "react-toastify";
import Items from "./Items";

const InvoiceTable = ({
  loading,
  allInvoice,
  pagination,
  setPagination,
  setReload,
  setAddInvoice,
  setCurrInvoice,
  setAllInvoice,
  bgColor,
  setMakePayment,
}) => {
  const [openItems, setOpenItems] = useState([]);
  const rows = allInvoice;

  const columns = [
    { id: "project_name", label: "Project" },
    { id: "due_date", label: "Due Date", style: { width: "110px" } },
    { id: "due_amount", label: "Due Amount", style: { width: "120px" } },
    { id: "intake_name", label: "Intake" },
    { id: "assignees", label: "Assigned", align: "center" },
    {
      id: "invoice_details",
      label: "Items",
      align: "center",
      style: { width: "140px" },
    },
    { id: "total", label: "Total", align: "right" },
    { id: "status", label: "Status", align: "center" },
    {
      id: "action",
      label: "Actions",
      style: { width: "150px" },
      align: "center",
    },
  ];
  const handleDelete = async (id) => {
    const result = await deleteInvoice({ id });
    if (result.success) {
      toast.success(result.message);
      setAllInvoice(allInvoice.filter((invoice) => invoice.id !== id));
    } else {
      toast.error(result.message);
    }
  };

  const handleEditBtn = (value) => {
    setCurrInvoice(value);
    setAddInvoice(true);
  };

  const handlePaymentBtn = (value) => {
    setCurrInvoice(value);
    setMakePayment(true);
  };

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 245px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                const due_dateForCompare = moment(row?.due_date).format(
                  "YYYY-MM-DD"
                );
                const today = moment().format("YYYY-MM-DD");
                if (due_dateForCompare < today && row?.status !== "paid") {
                  bgColor = "#fff1f1";
                } else {
                  bgColor = "white";
                }
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      key={row.id}
                      style={{ backgroundColor: bgColor }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "date_added" ? (
                              value ? (
                                moment(value).format("ll")
                              ) : (
                                "-"
                              )
                            ) : column.id === "due_date" ? (
                              value ? (
                                moment(value).format("ll")
                              ) : (
                                "-"
                              )
                            ) : column.id === "due_amount" ? (
                              row.symbol + (row.total - row.paid_amount)
                            ) : column.id === "total" ? (
                              <Typography color="#7171ff" variant="body2">
                                {row.symbol + value}
                              </Typography>
                            ) : column.id === "assignees" ? (
                              <PopoverAG items={value} />
                            ) : column.id === "invoice_details" ? (
                              value?.length ? (
                                <Box
                                  sx={{
                                    position: "relative",
                                    cursor: "pointer",
                                    "&:hover": {
                                      "& svg": {
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      },
                                    },
                                    "& svg": {
                                      position: "absolute",
                                      color:
                                        openItems === row?.id
                                          ? (theme) =>
                                              theme.palette.primary.main
                                          : "#777",
                                      top: "0px",
                                      right: "0px",
                                      cursor: "pointer",
                                      fontSize: "17px",
                                    },
                                  }}
                                  onClick={() => {
                                    setOpenItems(
                                      openItems === row?.id ? "" : row?.id
                                    );
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "150px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        overflow: "hidden",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        width: "100px",
                                        maxHeight: "22px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {`${value[0]?.item}`}
                                    </Typography>
                                    {value.length > 1 && (
                                      <Typography>
                                        & {value?.length - 1}+
                                      </Typography>
                                    )}
                                  </Box>
                                  <BsChevronExpand />
                                </Box>
                              ) : (
                                "-"
                              )
                            ) : column.id === "action" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip title="Make Payment">
                                  <IconButton
                                    disabled={row.status === "paid"}
                                    onClick={() => handlePaymentBtn(row)}
                                  >
                                    <MdOutlinePayment />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() => handleEditBtn(row)}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => handleDelete(row.id)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns?.length}
                      >
                        <Collapse
                          in={openItems === row?.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Box
                              sx={{
                                margin: "0 auto",
                                maxWidth: "580px",
                                zIndex: "-1",
                              }}
                            >
                              <Items
                                items={row.invoice_details}
                                symbol={row.symbol}
                              />
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 245px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default InvoiceTable;
