import { Edit } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PopoverAG from "components/ui/popoverAG";
import { updateInvoiceStatus } from "features/invoices/invoices/api/invoices";
import useFormatter from "hooks/useFormatter";
import useQueries from "hooks/useQueries";
import moment from "moment";
import { BsChevronExpand } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
export const invoiceStatuses = [
  {
    id: "paid",
    name: "Paid",
  },
  {
    id: "unpaid",
    name: "Unpaid",
  },
  {
    id: "hold",
    name: "Hold",
  },
  {
    id: "partial",
    name: "Partial",
  },
  {
    id: "inactive",
    name: "Inactive",
  },
];
const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleTableCheckBox,
  selectedRows,
  allInvoices,
  setAllInvoices,
  permissions,
  openItems,
  setOpenItems,
  ...restProps
}) => {
  const { setQueries } = useQueries();
  const { formatDate } = useFormatter();
  let getDueDateForCompare = moment(row?.due_date).format("YYYY-MM-DD");
  let getTodayDateForCompare = moment(new Date()).format("YYYY-MM-DD");
  let backgroundColor = "";
  let status = row?.status;
  if (getDueDateForCompare < getTodayDateForCompare && status !== "paid") {
    backgroundColor = "#fff1f1";
  } else {
    backgroundColor = "white";
  }

  const handleChangeStatus = async (value) => {
    setAllInvoices(
      allInvoices.map((invoice) =>
        invoice.id === row.id
          ? {
              ...row,
              status: value,
            }
          : invoice
      )
    );
    await updateInvoiceStatus({
      body_data: { status: value },
      id: row?.id,
    });
  };
  return (
    <TableRow
      {...restProps}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px", // <-- arbitrary value
          backgroundColor: backgroundColor,
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          disabled={!permissions["bulk action"]}
        />
      </TableCell>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "project_name" ? (
              value ? (
                value
              ) : (
                "-"
              )
            ) : column.id === "due_date" ? (
              formatDate(value)
            ) : column.id === "invoice_details" ? (
              value?.length ? (
                <Box
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    "&:hover": {
                      "& svg": {
                        color: (theme) => theme.palette.primary.main,
                      },
                    },
                    "& svg": {
                      position: "absolute",
                      color:
                        openItems === row?.id
                          ? (theme) => theme.palette.primary.main
                          : "#777",
                      top: "0px",
                      right: "0px",
                      cursor: "pointer",
                      fontSize: "17px",
                    },
                  }}
                  onClick={() => {
                    setOpenItems(openItems === row?.id ? "" : row?.id);
                  }}
                >
                  <Typography
                    sx={{
                      overflow: "hidden",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                      maxHeight: "22px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${
                      value[0]?.item.length > 9
                        ? `${value[0]?.item.substring(0, 9)}...`
                        : value[0]?.item || ""
                    } ${value?.length > 1 ? `& ${value?.length - 1}+` : ""}`}
                  </Typography>
                  <BsChevronExpand />
                </Box>
              ) : (
                "-"
              )
            ) : column.id === "tax" ? (
              <Typography color="#2fb42f" variant="body2">
                {"+ " + row.symbol + value}
              </Typography>
            ) : column.id === "discount" ? (
              <Typography color="#ff3535" variant="body2">
                {"- " + row.symbol + value}
              </Typography>
            ) : column.id === "total" ? (
              <Typography color="#7171ff" variant="body2">
                {row.symbol + value}
              </Typography>
            ) : column.id === "due_payment" ? (
              <Typography color="#ea6220" variant="body2">
                {row.paid_amount
                  ? `${row.symbol}${row.total - row.paid_amount}`
                  : row.symbol + row.total}
              </Typography>
            ) : column.id === "paid_amount" ? (
              <Typography color="#58d3e7" variant="body2">
                {value ? `${row.symbol}${value}` : "-"}
              </Typography>
            ) : column.id === "subtotal" ? (
              <Typography variant="body2">{row.symbol + value}</Typography>
            ) : column.id === "status" ? (
              <TextField
                disabled={!permissions["change status"]}
                size="small"
                value={value}
                select
                sx={{
                  width: "100px",
                  color: "#222",
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": { color: "#222" },
                }}
                variant="standard"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                {invoiceStatuses?.length > 0 &&
                  invoiceStatuses?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.name}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "assignees" ? (
              <PopoverAG items={value} />
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setQueries(`id=${row?.id}&m=0`)}
                    disabled={!permissions.update}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Trash">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.trash}
                  >
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
