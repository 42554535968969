import * as yup from "yup";

const validationSchema = yup.object({
  customer_id: yup.string("").required("Customeris Required"),
  project_customer_id: yup.string("").required("Project is Required"),
  reference_id: yup.string("").required("Reference is Required"),
  university_id: yup.string("").required("Reference is Required"),
  intake_id: yup.string("").required("Reference is Required"),
  status: yup.string("").required("Status is Required"),
  due_date: yup.string().when("status", {
    is: "paid",
    then: yup.string().notRequired(),
    otherwise: yup.string().required("Due Date is Required"),
  }),
});

export default validationSchema;
