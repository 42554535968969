import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateRangeField from "components/ui/dateRangeField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import getInitialvalues from "features/projectCustomers/utils/filterInitialValues";
import { useFormik } from "formik";
import * as yup from "yup";

const AdvanceFilter = ({
  openDrawer,
  setOpenDrawer,
  setReload,
  setFilters,
  filters,
  isFiltering,
  pagination,
  setPagination,
  customers,
  universities,
  references,
  branches,
}) => {
  const initialValues = getInitialvalues(filters);
  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setOpenDrawer(false);
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setOpenDrawer(false);
  };

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "400px",
        headingLeft: "Advanced Filters",
        headingRight: "",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack
          direction="column"
          gap="15px"
          p="20px"
          sx={{ height: "calc(100vh - 125px)", overflowY: "auto" }}
        >
          <MultiSelect
            options={branches}
            label="Branch"
            value={formik?.values?.branch_id}
            onChange={(value) => formik.setFieldValue("branch_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <MultiSelect
            options={references}
            label="Reference"
            value={formik?.values?.reference_id}
            onChange={(value) => formik.setFieldValue("reference_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <Select
            name="reference_type"
            options={[
              { id: "sub", label: "Sub" },
              { id: "master", label: "Master" },
            ]}
            label="Reference Type"
            formik={formik}
          />
          <MultiSelect
            options={universities}
            label="Universities"
            value={formik?.values?.university_id}
            onChange={(value) => formik.setFieldValue("university_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <Select
            name="source"
            options={[
              { id: "own", label: "Own" },
              { id: "loan", label: "Loan" },
            ]}
            label="Source of Fund"
            formik={formik}
          />
          <MultiSelect
            options={customers}
            label="Customers"
            value={formik?.values?.customer_id}
            onChange={(value) => formik.setFieldValue("customer_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <DateRangeField
            label="Followup Date"
            from="followup_date_from"
            to="followup_date_to"
            formik={formik}
          />
          <DateRangeField
            label="Created at"
            from="created_at_from"
            to="created_at_to"
            formik={formik}
          />
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          p="10px 20px"
          justifyContent="flex-end"
        >
          {isFiltering() ? (
            <Button variant="outlined" onClick={resetForm}>
              Reset Filter
            </Button>
          ) : (
            <Button variant="outlined" onClick={() => setOpenDrawer(false)}>
              Cancel
            </Button>
          )}
          <Button
            variant="solid"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            Filter Project Customers
          </Button>
        </Stack>
      </LocalizationProvider>
    </RightDrawer>
  );
};

export default AdvanceFilter;
