import { Box, Button, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  createReference,
  getAllSemesters,
  projectsList,
  updateReference,
} from "../../api/commissions";
import validationSchema from "../../schema";
import useQueries from "hooks/useQueries";
import useBase from "hooks/useBase";
import useApi from "hooks/useApi";
import handleError from "utils/handleError";
import ReactLoading from "react-loading";
import * as yup from "yup";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllReference,
  allReference,
}) => {
  const { row_id } = useQueries();
  const [projects, setProjects] = useState([]);
  const [currReference, setCurrReference] = useState(
    row_id ? {} : persistedData
  );
  const isUpdating = row_id ? true : false;
  const { base, updateBase } = useBase();
  const { customers, intakes, references, universities, currencies, branches } =
    base;
  const { loading, fetchData } = useApi();

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullReference = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/invoice/university-reference/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrReference(result?.data);
    };
    row_id && getFullReference();
  }, []);

  const statuses = [
    { id: "paid", name: "Paid" },
    { id: "unpaid", name: "Unpaid" },
    { id: "partial", name: "Partial" },
    { id: "cancelled", name: "Cancelled" },
    { id: "unsent", name: "Unsent" },
  ];

  const [commission, setcommission] = useState([]);
  const [semesters, setSemesters] = useState([]);

  useEffect(() => {
    const getSemesters = async () => {
      const result = await getAllSemesters();
      if (result?.data?.data) {
        setSemesters(result?.data?.data);
      }
    };
    getSemesters();
  }, []);

  const onSubmit = async (data) => {
    // reference_commission_date,
    //   reference_commission_amount,
    //   reference_commission_due_date,
    const {
      commission,
      student_fees_paid_amount,
      student_fees_due_amount,
      student_fees_paid_date,
      student_fees_due_date,
      university_commission_date,
      university_commission_amount,
      university_commission_due_date,
      ...rest
    } = data;
    const formattedData = commission
      ? commission.map((name) => ({
        semester_id: name,
        student_fees_paid_amount: student_fees_paid_amount[name],
        student_fees_due_amount: student_fees_due_amount[name],
        student_fees_paid_date: student_fees_paid_date
          ? student_fees_paid_date[name]
          : null,
        student_fees_due_date: student_fees_due_date
          ? student_fees_due_date[name]
          : null,
        university_commission_date: university_commission_date
          ? university_commission_date[name]
          : null,
        university_commission_amount: university_commission_amount[name],
        // reference_commission_date: reference_commission_date
        //   ? reference_commission_date[name]
        //   : null,
        university_commission_due_date: university_commission_due_date
          ? university_commission_due_date[name]
          : null,
        // reference_commission_amount: reference_commission_amount[name],
        // reference_commission_due_date: reference_commission_due_date
        //   ? reference_commission_due_date[name]
        //   : null,
      }))
      : [];
    setcommission(formattedData);

    let finalData = {
      ...rest,
      commission: formattedData || [],
    };

    const result = await fetchData(
      isUpdating
        ? updateReference(row_id, finalData)
        : createReference(finalData)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllReference(
          allReference.map((reference) =>
            reference.id === currReference.id
              ? { ...currReference, ...result?.data }
              : reference
          )
        );
      } else {
        setAllReference([result?.data, ...allReference]);
      }
      // updateBase(["invoices"]);
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  const initialValues = {
    customer_id: "",
    project_customer_id: "",
    reference_id: "",
    student_id: "",
    course_name: "",
    university_id: "",
    intake_id: "",

    branch_id: "",
    currency_id: "",
    due_date: "",
    note: "",
    status: "",
    selectedSemesters: [],
    commission: [],

    student_fees_paid_amount: Object.fromEntries(
      semesters.map((semester) => [semester.id, 0])
    ),
    student_fees_due_amount: Object.fromEntries(
      semesters.map((semester) => [semester.id, 0])
    ),

    student_fees_paid_date: null,
    student_fees_due_date: null,

    university_commission_date: null,
    university_commission_amount: Object.fromEntries(
      semesters.map((semester) => [semester.id, 0])
    ),
    university_commission_due_date: null,
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let tempinitialValues = {
        customer_id: data?.customer_id,
        project_customer_id: data?.project_customer_id,
        reference_id: data?.reference_id,
        student_id: data?.student_id,
        course_name: data?.course_name,
        university_id: data?.university_id,
        intake_id: data?.intake_id,

        branch_id: data?.branch_id,
        currency_id: data?.currency_id,
        due_date: data?.due_date,
        note: data?.note,
        status: data?.status,
        selectedSemesters: data?.semester_commissions?.map(
          (commission) => commission.semester_id
        ),

        commission: data?.semester_commissions?.map(
          (commission) => commission.semester_id
        ),

        student_fees_paid_amount: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_paid_amount,
          ])
        ),
        student_fees_due_amount: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_due_amount,
          ])
        ),

        student_fees_paid_date: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_paid_date,
          ])
        ),
        student_fees_due_date: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_due_date,
          ])
        ),

        university_commission_amount: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_amount,
          ])
        ),
        university_commission_date: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_date,
          ])
        ),
        university_commission_due_date: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_due_date,
          ])
        ),
      };
      return tempinitialValues;
    } else return initialValues;
  };

  const formik = useFormik({
    initialValues: getInitialValues(currReference),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getProjects = async () => {
      const result = await projectsList({
        id: formik.values.customer_id || "",
      });
      if (result.success) setProjects(result.data);
    };
    getProjects();
  }, [formik.values.customer_id]);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: currReference?.id ? "Edit Commision" : "New Commision",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box p="20px">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              pb: "20px",
              borderBottom: "1px dashed #ddd",
              mb: "20px"
            }}
          >
            <InputField
              name="student_id"
              label="Student ID"
              multiline
              formik={formik}
            />
            <InputField
              name="course_name"
              label="Course Name"
              multiline
              formik={formik}
            />
            <Select
              options={customers}
              label="Customer"
              value={formik?.values?.customer_id}
              onChange={(value) => formik.setFieldValue("customer_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              disabled={!formik.values.customer_id}
              options={projects}
              label="Project"
              value={formik?.values?.project_customer_id}
              onChange={(value) =>
                formik.setFieldValue("project_customer_id", value)
              }
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={references}
              label="References"
              value={formik?.values?.reference_id}
              onChange={(value) => formik.setFieldValue("reference_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={universities}
              label="University"
              value={formik?.values?.university_id}
              onChange={(value) => formik.setFieldValue("university_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={intakes}
              label="Intake"
              value={formik?.values?.intake_id}
              onChange={(value) => formik.setFieldValue("intake_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={branches}
              label="Branch Name"
              name="branch_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={currencies}
              label="Currency"
              name="currency_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={statuses}
              label="Status"
              name="status"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <DateTimePicker
              fullWidth
              name="due_date"
              label="Due Date"
              type="date"
              formik={formik}
            />

            <Box sx={{ gridColumn: "span 3" }}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Note"
                multiline
                rows={3}
                variant="outlined"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
              />
            </Box>
          </Box>
          <Box>
            <MultiSelect
              disableRemoveOption
              name="commission"
              label="Select Semesters"
              formik={formik}
              options={semesters}
              selectProps={{
                id: "id",
                label: "name",
              }}
            />
            {formik.values.commission?.length > 0 &&
              formik.values.commission.map((name) => (
                <Box key={name} sx={{ borderTop: "1px solid #ddd", p: "10px 20px", mt: "20px", mx: "-20px", px: "20px" }}>
                  {semesters.map((semester) => (
                    <h3
                      key={semester.id}
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      {semester.id == name ? semester.name : ""}
                    </h3>
                  ))}

                  <Box
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "20px",
                          pb: "20px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Student Fees Paid Amount"
                          variant="outlined"
                          size="small"
                          type="number"
                          name={`student_fees_paid_amount.${name}`}
                          value={
                            formik.values?.student_fees_paid_amount
                              ? formik.values.student_fees_paid_amount[name]
                              : 0
                          }
                          onChange={formik.handleChange}
                        />
                        <DatePicker
                          label="Student Fees Paid Date"
                          name={`student_fees_paid_date.${name}`}
                          value={
                            formik.values.student_fees_paid_date
                              ? formik.values.student_fees_paid_date[name]
                                ? formik.values.student_fees_paid_date[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `student_fees_paid_date.${name}`,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Student Fees Due Amount"
                          variant="outlined"
                          size="small"
                          type="number"
                          name={`student_fees_due_amount.${name}`}
                          value={
                            formik.values.student_fees_due_amount
                              ? formik.values.student_fees_due_amount[name]
                              : 0
                          }
                          onChange={formik.handleChange}
                        />
                        <DatePicker
                          label="Student Fees Due Date"
                          name={`student_fees_due_date.${name}`}
                          value={
                            formik.values.student_fees_due_date
                              ? formik.values.student_fees_due_date[name]
                                ? formik.values.student_fees_due_date[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `student_fees_due_date.${name}`,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "10px",
                          pb: "20px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Commission Amount"
                          variant="outlined"
                          size="small"
                          type="number"
                          name={`university_commission_amount.${name}`}
                          value={
                            formik.values.university_commission_amount
                              ? formik.values.university_commission_amount[name]
                              : ""
                          }
                          onChange={formik.handleChange}
                        />
                        <DatePicker
                          label="Commission Date"
                          name={`university_commission_date.${name}`}
                          value={
                            formik.values.university_commission_date
                              ? formik.values.university_commission_date[name]
                                ? formik.values.university_commission_date[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `university_commission_date.${name}`,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                        <DatePicker
                          label="Commission Due Date"
                          name={`university_commission_due_date.${name}`}
                          value={
                            formik.values.university_commission_due_date
                              ? formik.values.university_commission_due_date[
                                name
                              ]
                                ? formik.values.university_commission_due_date[
                                name
                                ]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `university_commission_due_date.${name}`,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Box>
              ))}
          </Box>

          <Button
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
            sx={{ gridColumn: "span 3", mt: "20px" }}
          >
            {currReference?.id ? "Update Commision" : "Save Commision"}
          </Button>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
