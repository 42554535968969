import { Avatar, AvatarGroup, Box, Popover } from "@mui/material";
import UNIVERSAL from "config";
import React, { useState } from "react";

const PopoverAG = ({ items = [], size }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPO = Boolean(anchorEl);

  return (
    <Box>
      <AvatarGroup
        aria-owns={openPO ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        total={items?.length}
        sx={{
          justifyContent: "center",
          "& .MuiAvatar-root": {
            width: size || 28,
            height: size || 28,
            fontSize: 12,
          },
        }}
      >
        {items?.slice(0, 2).map((item) => (
          <Avatar
            key={item?.name}
            alt={item?.name}
            src={UNIVERSAL.BASEURL + item?.image}
          />
        ))}
      </AvatarGroup>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openPO}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "25px auto",
            padding: "12px",
            alignItems: "center",
            gap: "10px",
            "& .MuiAvatar-root": {
              width: 28,
              height: 28,
              fontSize: 12,
            },
          }}
        >
          {items?.map((item) => (
            <React.Fragment>
              <Avatar
                key={item.name}
                alt={item?.name}
                src={UNIVERSAL.BASEURL + item?.image}
              />
              <span key={item.name}>{item?.name}</span>
            </React.Fragment>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default PopoverAG;
