import { Edit } from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useFormatter from "hooks/useFormatter";
import { setLoading } from "lib/Reducer/loadingSlice";
import moment from "moment";
import { MdDelete, MdEdit } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  updateFollowupDate,
  updateInvoiceBankStatus,
} from "../../api/invoiceBank";
import useQueries from "hooks/useQueries";
const isGivenDateLessThanToday = (givenDate) => {
  const today = moment();
  return moment(givenDate).isBefore(today, "day");
};

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  setReload,
  bankInvoiceStatuses,
  setAllInvoiceBank,
  allInvoiceBank,
  openNote,
  setOpenNote,
  ...restProps
}) => {
  const { setQueries } = useQueries();
  const { formatDate, formatCurrency } = useFormatter();
  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );

  const currencies = useSelector((state) => state?.base?.base_data?.currencies);

  const symbol = currency_id
    ? currencies?.find((c) => c.id === currency_id).symbol
    : "";
  const handleChangeStatus = async (value) => {
    setAllInvoiceBank(
      allInvoiceBank.map((invoice) =>
        invoice.id === row.id
          ? {
              ...row,
              bank_invoice_status_id: value,
            }
          : invoice
      )
    );
    let res = await updateInvoiceBankStatus({
      body_data: { bank_invoice_id: row.id, bank_invoice_status_id: value },
      id: row?.id,
    });
    if (res?.success) {
      toast.success(res?.message);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const dispatch = useDispatch();

  const handleFollowupDate = async (followup_date) => {
    dispatch(setLoading(true));
    const body_data = {
      bank_invoice_id: row?.id,
      followup_date,
    };
    try {
      const result = await updateFollowupDate({ body_data });
      if (result.success) {
        toast.success(result.message);
        setReload((pre) => !pre);
      } else {
        toast.error(result.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <TableRow
      {...restProps}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 20px",
          backgroundColor: isGivenDateLessThanToday(row?.followup_date)
            ? "#fff1f1"
            : "",
        },
      }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "interest_amount" ||
            column.id === "advanced_paid" ||
            column.id === "expense" ||
            column.id === "total_charge" ||
            column.id === "total_due" ||
            column.id === "total_paid" ? (
              (column.id !== "interest_amount" ? symbol : "") +
              formatCurrency(value)
            ) : column.id === "loan_amount" ? (
              <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                {value} Lakh
              </Typography>
            ) : column.id === "created_at" ||
              column.id === "account_open_date" ||
              column.id === "account_close_date" ? (
              formatDate(value)
            ) : column.id === "followup_date" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>{formatDate(value)}</span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Custom input"
                    value={value}
                    onChange={(newValue) => {
                      handleFollowupDate(moment(newValue).format("YYYY-MM-DD"));
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {InputProps?.endAdornment}
                        <input
                          style={{
                            width: "0px",
                            height: "0px",
                            opacity: 0,
                          }}
                          ref={inputRef}
                          {...inputProps}
                        />
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </Box>
            ) : column.id === "bank_invoice_status_id" ? (
              <TextField
                size="small"
                value={value}
                select
                sx={{
                  width: "100px",
                  color: "#222",
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": { color: "#222" },
                }}
                variant="standard"
                onChange={(e) => {
                  handleChangeStatus(e.target.value);
                }}
              >
                {bankInvoiceStatuses?.length > 0 &&
                  bankInvoiceStatuses?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.name}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "last_note" ? (
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    "& svg": {
                      color: (theme) => theme.palette.primary.main,
                    },
                  },
                  "& svg": {
                    position: "absolute",
                    color:
                      openNote === row?.id
                        ? (theme) => theme.palette.primary.main
                        : "#777",
                    top: "0px",
                    right: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                  },
                }}
                onClick={() => setOpenNote(openNote === row?.id ? "" : row?.id)}
              >
                <Typography
                  sx={{
                    overflow: "hidden",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                    maxHeight: "22px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value || "-"}
                </Typography>
                <MdEdit />
              </Box>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=0`)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value || "-"
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
