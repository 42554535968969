import { Timeline } from "@mui/lab";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Avatar, Box, Typography } from "@mui/material";
import ActivitySkeleton from "components/skeletons/ActivitySkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LatestActivity = () => {
  const { loading, fetchData } = useApi();
  const [activities, setActivities] = useState([]);
  const settings = useSelector((state) => state?.base?.base_data?.settings);

  const getActivityList = async () => {
    const endpopint = {
      method: "post",
      url: "/api/admin/activity-log/list?page=1&limit=30",
    };
    const result = await fetchData(endpopint, false);
    if (result.success) setActivities(result?.data?.data);
  };

  useEffect(() => {
    getActivityList();
  }, []);

  useEffect(() => {
    const pusher = new Pusher(settings?.notification?.pusher_key || "", {
      cluster: settings?.notification?.pusher_cluster || "",
      useTLS: true,
    });

    const channel = pusher.subscribe("activity");
    channel.bind("activity", function ({ code }) {
      if (code === 200) getActivityList();
    });

    return () => {
      pusher.unsubscribe("activity");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
      }}
    >
      <Typography sx={{ borderBottom: "1px solid #efefef", p: "10px 15px" }}>
        Latest Activities
      </Typography>
      <Box sx={{ height: "550px", overflowY: "auto" }}>
        {loading ? (
          <Box>
            {[1, 2, 3, 4, 5].map((n) => (
              <ActivitySkeleton key={n} />
            ))}
          </Box>
        ) : (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              margin: "0px",
              padding: "0px 10px",
            }}
          >
            {activities?.map((activity, i) => (
              <TimelineItem key={i}>
                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    sx={{ borderColor: "#efefef", border: "none", p: "0" }}
                  >
                    <Avatar
                      sx={{ width: "30px", height: "30px" }}
                      src={
                        UNIVERSAL.BASEURL + activity?.user_image ||
                        activity?.customer_image
                      }
                    />
                  </TimelineDot>

                  {i < activities.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ py: "5px", px: "10px", mb: "10px " }}>
                  <Box
                    sx={{
                      border: "1px solid #f7f7f7",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="h6">{activity?.title}</Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#444",
                        marginBottom: "10px",
                      }}
                    >
                      {moment(activity?.created_at).format("lll")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#444",
                      }}
                    >
                      {activity?.description}
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )}
      </Box>
    </Box>
  );
};

export default LatestActivity;
