import {
  Add,
  DownloadOutlined,
  FilterAltOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import useQueries from "hooks/useQueries";
import { useState } from "react";

const Header = ({
  activeMenu,
  setActiveMenu,
  setOpenFilter,
  setSearch,
  setPagination,
  pagination,
  permissions,
  search,
  filters,
}) => {
  const { setQueries } = useQueries();
  const { loading, fetchData } = useApi();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useDidMountEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  const handleExport = async () => {
    const res = await fetchData(
      {
        method: "post",
        url: "/api/admin/project-customer/export",
        data: {
          type: "xlsx",
          trash_export: false,
          search,
          ...filters,
        },
      },
      false
    );
    if (res.success) window.open(res?.data?.file);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!(permissions["list(own)"] || permissions["list(global)"]) && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          variant={activeMenu === "summary" ? "solid" : "outlined"}
          startIcon={<TimelineOutlined />}
          onClick={() =>
            setActiveMenu(activeMenu === "summary" ? "" : "summary")
          }
          disabled={!permissions.summery}
        >
          Summery
        </Button>
        <Button
          variant={"outlined"}
          startIcon={<FilterAltOutlined />}
          onClick={() => setOpenFilter(true)}
          disabled={!(permissions["list(own)"] || permissions["list(global)"])}
        >
          Advanced Filters
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={handleExport}
          disabled={!permissions.export}
        >
          Export
        </LoadingButton>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setQueries("m=0")}
          disabled={!permissions.create}
        >
          Add Customer To Project
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
