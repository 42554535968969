import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

const Items = ({ items, symbol }) => {
  const rows = items;

  const columns = [
    { id: "fee", label: "Item", minWidth: 100 },
    { id: "subtotal", label: "Subtotal", minWidth: 100 },
    { id: "tax", label: "Tax", minWidth: 100 },
    { id: "discount", label: "Discount", minWidth: 100 },
    { id: "total", label: "Total" },
  ];

  return (
    <TableContainer sx={{ height: "250px", overflowX: "auto" }}>
      <Table stickyHeader>
        <TableBody>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                sx={{ fontWeight: "500" }}
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
          {rows?.map((row) => {
            return (
              <TableRow hover role="checkbox" key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.id === "fee" ? (
                        value?.name
                      ) : column.id === "tax" ? (
                        <Typography color="#2fb42f" variant="body2">
                          {"+ " + symbol + value}
                        </Typography>
                      ) : column.id === "discount" ? (
                        <Typography color="#ff3535" variant="body2">
                          {"- " + symbol + value}
                        </Typography>
                      ) : column.id === "total" ? (
                        <Typography color="#7171ff" variant="body2">
                          {symbol + value}
                        </Typography>
                      ) : column.id === "subtotal" ? (
                        <Typography variant="body2">
                          {symbol + value}
                        </Typography>
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Items;
