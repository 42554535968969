import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";

let config = {
  readonly: false,
  height: 300,
  allowResizeX: false,
  allowResizeY: true,
  buttons: [
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "align",
    "undo",
    "redo",
  ],
};
const Editor = ({ formik, name = "description", initVal=null }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(formik?.values?.[name]);

  useEffect(() => {
    setContent(formik?.values?.[name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.template_id]);
  useEffect(() => {
    initVal && setContent(initVal);
  }, [initVal]);
  return (
    <>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1}
        onBlur={(newContent) => setContent(newContent)}
        onChange={(newContent) => {
          formik.setFieldValue(name, newContent);
        }}
      />
      <p
        style={{
          color: "red",
          fontSize: "12px",
          margin: 0,
        }}
      >
        {formik.touched[name] && formik.errors[name] ? (
          <div>{formik.errors[name]}</div>
        ) : null}
      </p>
    </>
  );
};

export default Editor;
