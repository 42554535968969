import { PrintRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import UNIVERSAL from "config";
import useApiCall from "hooks/useApiCall";
import useFormatter from "hooks/useFormatter";
import { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { viewAllMakePayment, viewAllPayment } from "../../api/invoiceBank";
import { getOrdinal } from "./payments/PaymentTable";

const Details = ({ profile, symbol, loading }) => {
  const { formatDate, formatCurrency } = useFormatter();

  const site_logo = useSelector(
    (state) => state?.base?.base_data?.settings?.general?.site_logo
  );

  const printableContentRef = useRef();

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <>
          <ReactToPrint
            documentTitle={profile?.invoice_number}
            trigger={() => (
              <Button
                variant="outlined"
                startIcon={<PrintRounded />}
                sx={{
                  position: "absolute",
                  right: "3rem",
                  top: "2rem",
                }}
              >
                Print
              </Button>
            )}
            content={() => printableContentRef.current}
          />

          <Box ref={printableContentRef} p={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <img
                src={UNIVERSAL.BASEURL + site_logo}
                alt=""
                style={{
                  maxHeight: "100px",
                  maxWidth: "240px",
                  marginLeft: "-15px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
              }}
            >
              <Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Customer Name:
                  </Typography>
                  <Typography>{profile?.customer_name}</Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Account Number:
                  </Typography>
                  <Typography>{profile?.account_number}</Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Account Name:
                  </Typography>
                  <Typography> {profile?.account_name}</Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Applicant Name:
                  </Typography>
                  <Typography> {profile?.applicant_name}</Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">Bank Name:</Typography>
                  <Typography>{profile?.bank_name}</Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Loan Amount:
                  </Typography>
                  <Typography> {profile?.loan_amount || "0"} Lakh </Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Reference Name:
                  </Typography>
                  <Typography>{profile?.reference_name}</Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">Open Date:</Typography>
                  <Typography>
                    {formatDate(profile?.account_open_date)}
                  </Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">Close Date:</Typography>
                  <Typography>
                    {formatDate(profile?.account_close_date)}
                  </Typography>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                  <Typography textTransform="uppercase">
                    Contact Number:
                  </Typography>
                  <Typography>{profile?.contact_number}</Typography>
                </Box>
              </Box>
            </Box>

            <Typography
              variant="body1"
              textTransform="capitalize"
              mt={2}
              className="hide-on-print"
            >
              Note: {profile?.note}
            </Typography>

            <TableDetails
              formatCurrency={formatCurrency}
              formatDate={formatDate}
              symbol={symbol}
              title={"Invoice History"}
              profile={profile}
              apiCall={viewAllPayment}
              columns={[
                { id: "month", label: "Month" },
                { id: "interest_amount", label: "Interest Rate" },
                {
                  id: "charge",
                  label: "Charge/Month",
                },
              ]}
            />
            <TableDetails
              formatCurrency={formatCurrency}
              formatDate={formatDate}
              symbol={symbol}
              title={"Payment History"}
              profile={profile}
              apiCall={viewAllMakePayment}
              columns={[
                {
                  id: "payment_method",
                  label: "Payment Method",
                },
                {
                  id: "total_paid",
                  label: "Total Paid",
                },
                { id: "payment_date", label: "Payment Date" },
              ]}
            />
            <Box
              sx={{
                margin: "20px 0 0 auto",
                padding: "20px 30px",
                border: "1px solid #caddff",
                bgcolor: "#fbfcff",
                width: "300px",
                mt: "30px",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                }}
              >
                <Typography align="right">Total Charge</Typography>
                <Typography align="right">
                  {symbol + formatCurrency(profile?.total_charge)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                }}
              >
                <Typography align="right">Due Amount</Typography>
                <Typography align="right">
                  {symbol + formatCurrency(profile?.total_due)}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderTop: "1px solid #cbcbcb",
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                  pt: "5px",
                  mt: "5px",
                }}
              >
                <Typography align="right" fontSize="16px">
                  Total Paid
                </Typography>
                <Typography align="right" fontSize="16px">
                  {symbol + formatCurrency(profile?.total_paid)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Details;

const TableDetails = ({
  profile,
  title,
  symbol,
  apiCall,
  columns,
  formatDate,
  formatCurrency,
}) => {
  const [allData, setData] = useState([]);
  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    fetchListData(
      apiCall,
      {
        id: profile?.id,
      },
      false
    );
  }, []);

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data]);

  const payRows = allData || [];
  return loading ? (
    [...Array(5)].map((c, i) => <Skeleton key={i} width={"100%"} height={90} />)
  ) : (
    <>
      <Typography
        variant="h3"
        sx={{
          mt: "25px",
          pt: "25px",
          pb: "10px",
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      {payRows.length > 0 ? (
        <>
          <TableContainer sx={{ mt: "10px" }}>
            <Table stickyHeader>
              <TableBody>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ fontWeight: "500" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
                {payRows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "interest_amount" ? (
                              formatCurrency(value)
                            ) : column.id === "total_paid" ||
                              column.id === "charge" ? (
                              <>{symbol + formatCurrency(value)} </>
                            ) : column.id === "payment_date" ? (
                              formatDate(value)
                            ) : column.id === "month" ? (
                              getOrdinal(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            py: "40px",
            background: "#fbfbfb",
            mt: "10px",
          }}
        >
          No History!
        </Typography>
      )}
    </>
  );
};
