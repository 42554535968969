import { Box, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import useFormatter from "hooks/useFormatter";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

const Summary = ({ filters, search }) => {
  const { formatNumber } = useFormatter();
  const [invoiceSummary, setInvoiceSummary] = useState({});
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getInvoiceSummary = async () => {
      const endpoint = {
        method: "post",
        url: "api/admin/invoice/summery",
        data: { ...filters, search },
      };
      const result = await fetchData(endpoint, false);
      setInvoiceSummary(result?.data || {});
    };
    getInvoiceSummary();
  }, [search, filters]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 145px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
              borderTop: "1px solid #ddd",
            }}
          >
            <Box
              sx={{
                bgcolor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography>Total Project</Typography>
              <Typography variant="h3">
                {invoiceSummary?.total_project || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography>Total Customer</Typography>
              <Typography variant="h3">
                {invoiceSummary?.total_customer || 0}
              </Typography>
            </Box>

            <Box
              sx={{
                bgcolor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography>Total</Typography>
              <Typography variant="h3">{invoiceSummary?.total || 0}</Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography>Total Paid</Typography>
              <Typography variant="h3">
                {formatNumber(invoiceSummary?.total_paid) || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography>Total Due</Typography>
              <Typography variant="h3">
                {invoiceSummary?.total_due || 0}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Summary;
