import { PrintRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemText,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";

const CommisionAll = ({
  university_name,
  uni_or_ref_id,
  table_columns,
  commission_type
}) => {
  const printableContentRef = useRef();
  let [allData, setAllData] = useState([]);
  let { loading, fetchData } = useApi();
  const site_logo = useSelector((state) => state?.base?.site_info?.site_logo);

  useEffect(() => {
    async function fetchAllCommision() {
      const endpoint = {
        method: "post",
        url: `/api/admin/invoice/university-reference/commission/list`,
        data: {
          university_id: uni_or_ref_id,
          commission_type
        },
      };
      const res = await fetchData(endpoint, false);
      if (res.success) {
        setAllData(res.data);
      }
    }
    fetchAllCommision();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  console.log("allData", allData);

  const curentCurrencySymbol = allData?.currency?.symbol;

  const totalCommissionAmount = allData.reduce((total, item) => {
    const universityCommissions = item?.university_commissions;
    const commissionAmounts = universityCommissions?.map(i => i?.university_commission_amount || 0);
    const itemTotal = commissionAmounts?.reduce((acc, commissionAmount) => acc + commissionAmount, 0) || 0;
    return total + itemTotal;
  }, 0);

  return loading ? (
    [...Array(5)].map((c, i) => <Skeleton key={i} width={"100%"} height={90} />)
  ) : (
    <Box m="-25px">
      <ReactToPrint
        documentTitle={university_name}
        trigger={() => (
          <Button
            variant="outlined"
            startIcon={<PrintRounded />}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
            }}
          >
            Print
          </Button>
        )}
        content={() => printableContentRef.current}
      />

      <Box ref={printableContentRef} p={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "40px",
            marginTop: "20px",
          }}
        >
          <img
            src={UNIVERSAL.BASEURL + site_logo}
            alt=""
            style={{
              maxHeight: "100px",
              maxWidth: "240px",
            }}
          />
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Typography textTransform="uppercase">
                University Name:
              </Typography>
              <Typography sx={{ textAlign: "end" }}>{university_name}</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          {!!allData?.length ? (
            <Box
              sx={{
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <TableContainer sx={{ mt: "0px" }}>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow>
                      {table_columns?.map((column) => (
                        <TableCell
                          sx={{ fontWeight: "500" }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                    {allData?.map((item) => {
                      const university_commissions = item?.university_commissions;
                      const row = {
                        course_name: item?.course_name,
                        intake_name: item?.intake?.name,
                        student_id: item?.student_id,
                        customer_name: item?.customer?.name,
                      };
                      return (
                        <TableRow hover role="checkbox" key={item.id}>
                          {table_columns?.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column?.id === "university_commission_due_date" ?
                                    <Typography>
                                      {university_commissions?.map(i => <li style={{ fontSize: "0.875rem", listStyle: "none" }} key={i.id}>
                                        {i?.university_commission_due_date || "-"}
                                      </li>)}
                                    </Typography>
                                    : column?.id === "university_commission_date" ?
                                      <Typography>
                                        {university_commissions?.map(i => <li style={{ fontSize: "0.875rem", listStyle: "none" }} key={i.id}>
                                          {i?.university_commission_date || "-"}
                                        </li>)}
                                      </Typography>
                                      : column?.id === "university_commission_amount" ?
                                        <Typography>
                                          {university_commissions?.map(i => <li style={{ fontSize: "0.875rem", listStyle: "none" }} key={i.id}>
                                            {i?.university_commission_amount || "-"}
                                          </li>)}
                                        </Typography>
                                        : value || "-"}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  margin: "20px 0 0 auto",
                  padding: "20px 30px",
                  border: "1px solid #caddff",
                  bgcolor: "#fbfcff",
                  width: "300px",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "150px auto",
                  }}
                >
                  <Typography align="left" fontSize="16px">
                    Total Commission
                  </Typography>
                  <Typography align="right" fontSize="16px">
                    {allData[0].currency?.symbol}
                    {" "}
                    {totalCommissionAmount}

                  </Typography>
                </Box>
              </Box>
            </Box>
          )
            : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  width: "100%",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  marginTop: "40px",
                }}
              >
                <Typography>No Data Found</Typography>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  );
};

export default CommisionAll;
