import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Activity from "components/common/activity";
import Attachment from "components/common/attachment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import { useState } from "react";
import View from "./View";
import Comments from "./comment/Comments";

const ViewKnowledge = ({
  openDrawer,
  setOpenDrawer,
  currPost,
  setReload,
  statuses,

  permissions,
}) => {
  const post = currPost || {};
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "900px",
        headingLeft: "#" + post.id + " - " + post?.name,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="View" value="1" />
              <Tab label="Comment" value="2" />
              <Tab label="Attachment" value="3" />
              <Tab label="Activity" value="4" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              <View post={post} />
            </TabPanel>
            <TabPanel value="2">
              <Comments post={post} />
            </TabPanel>
            <TabPanel value="3">
              <Attachment id={post?.id} route="knowledge" />
            </TabPanel>
            <TabPanel value="4">
              <Activity
                activityList={{
                  method: "get",
                  url: `/api/admin/knowledge/activity/${post?.id}`,
                }}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewKnowledge;
