import ArrowBack from "@mui/icons-material/ArrowBack";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { LuSettings2 } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

const Menu = ({ menuList, activeMenuType, setActiveMenuType }) => {
  const { pathname } = useLocation();
  const extract_path = (path, position) => {
    const first_path = path.split("/")[position];
    return first_path;
  };
  const [activeMenu, setActiveMenu] = useState(
    `/${extract_path(pathname, 1)}${
      extract_path(pathname, 1) === "setup"
        ? `/${extract_path(pathname, 2)}`
        : ""
    }`
  );
  const handleDropdown = (type) => {
    if (activeMenu === type) {
      setActiveMenu("");
    } else {
      setActiveMenu(type);
    }
  };
  useEffect(() => {
    if (extract_path(pathname, 1) === "setup") {
      setActiveMenuType("setupMenu");
    }
  }, [extract_path(pathname, 1)]);

  return (
    <>
      {activeMenuType !== "main" && (
        <Link to={""} onClick={() => setActiveMenuType("main")}>
          <ArrowBack />
          <span>Return to main menu</span>
        </Link>
      )}
      {menuList.map((singleMenu, i) => (
        <React.Fragment key={i}>
          {singleMenu.child ? (
            <>
              <div
                className={`drop-down-menu ${activeMenu === singleMenu.path}`}
                onClick={() => handleDropdown(singleMenu.path)}
              >
                <div>
                  {singleMenu.icon}
                  <span>{singleMenu.label}</span>
                </div>
                <span>
                  <HiChevronRight />
                </span>
              </div>
              <div className={`dd-items ${activeMenu === singleMenu.path}`}>
                {singleMenu?.child?.map((smc) => (
                  <NavLink to={smc.path} key={smc.path}>
                    {smc.label}
                  </NavLink>
                ))}
              </div>
            </>
          ) : (
            <NavLink
              to={singleMenu.path}
              onClick={() => handleDropdown(extract_path(singleMenu.path, 1))}
            >
              {singleMenu.icon}
              <span>{singleMenu.label}</span>
            </NavLink>
          )}
        </React.Fragment>
      ))}
      {activeMenuType === "main" && (
        <Link to={"setup"} onClick={() => setActiveMenuType("setupMenu")}>
          <LuSettings2 />
          <span>Setup</span>
        </Link>
      )}
    </>
  );
};

export default Menu;
