import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import NoteSkeleton from "components/skeletons/NoteSkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import useDidMountEffect from "hooks/useDidMountEffect";
import moment from "moment";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommentForm from "./CommentForm";
import ViewAttachment from "./ViewCommentAttachment";
function Action({ comment, handleDelete, handleEdit }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleDelete(comment);
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleEdit(comment);
          }}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
}

const Comment = ({
  comment,
  handleDelete,
  editComment,
  setEditComment,
  postId,
  setCommnets,
}) => {
  const [viewAtt, setViewAtt] = useState(false);
  const [currFile, setCurrFile] = useState({});
  const { loading: editLoading, fetchData: editCommentApi } = useApi();

  const handleFileView = (comment) => {
    if (comment?.attachment_type.split("/").includes("image")) {
      setCurrFile(comment);
      setViewAtt(true);
    } else {
      window.open(UNIVERSAL.BASEURL + comment?.file, { replace: true });
    }
  };
  let handleEdit = (commentEdit) => {
    if (commentEdit?.id === editComment) {
      setEditComment("");
    } else {
      setEditComment(commentEdit?.id);
    }
  };

  let onSubmitEdit = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    let endpoint = {
      method: "post",
      url: `/api/admin/task/comment/update/${comment.id}`,
      data: formData,
    };

    let result = await editCommentApi(endpoint, false);
    if (result.success) {
      let data = result.data;
      setCommnets((prev) =>
        prev.map((item) => (item.id === comment.id ? { ...data } : item))
      );
      setEditComment("");
    }
  };
  return (
    <>
      <Box
        key={comment?.id}
        sx={{
          display: "grid",
          gridTemplateColumns: "50px auto 30px",
          padding: "10px",
          borderTop: "1px dashed #ddd",
        }}
      >
        <Box>
          <Avatar
            alt={comment?.name}
            src={UNIVERSAL.BASEURL + comment?.image}
          />
        </Box>

        <Box sx={{ paddingBottom: "15px" }}>
          <Typography variant="h6">{comment?.name}</Typography>
          <Box sx={{ fontSize: "12px" }}>
            {moment(comment?.created_at).format("lll")}
          </Box>
          <Box
            sx={{ fontSize: "14px", paddingTop: "10px", paddingBottom: "10px" }}
          >
            {comment?.comment}
          </Box>
          {comment?.attachment_type?.split("/").includes("image") ? (
            <Button onClick={() => handleFileView(comment)}>
              <img
                style={{
                  width: "10rem",
                }}
                alt=""
                src={UNIVERSAL.BASEURL + comment?.file}
              />
            </Button>
          ) : (
            comment.file && (
              <Chip
                sx={{ mb: "15px" }}
                label={comment?.attachment_name}
                component="a"
                href={UNIVERSAL?.BASEURL + comment.file}
                target="_blank"
                variant="outlined"
                clickable
              />
            )
          )}
        </Box>

        <Box>
          <Action
            comment={comment}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Box>
      </Box>
      {editComment === comment?.id && (
        <Box p={2}>
          <CommentForm
            setCommnets={setCommnets}
            data={comment}
            postId={postId}
            placeholder={"Edit Comment"}
            btnText={"Edit Comment"}
            onSubmit={onSubmitEdit}
            loading={editLoading}
          />
        </Box>
      )}
      {viewAtt && <ViewAttachment setViewAtt={setViewAtt} file={currFile} />}
    </>
  );
};

const Comments = ({ post }) => {
  const user_id = useSelector((state) => state?.auth.user_details?.user?.id);

  const settings = useSelector((state) => state?.base?.base_data?.settings);
  const [reload, setReload] = useState(null);
  const [editComment, setEditComment] = useState("");

  const { loading, fetchData } = useApi();

  const { fetchData: deleteComment } = useApi();
  const { loading: addCmntloading, fetchData: addComment } = useApi();
  let [commnets, setCommnets] = useState([]);
  useEffect(() => {
    async function allCommnts() {
      const endpoint = {
        method: "get",
        url: `/api/admin/task/comment/${post?.id}`,
      };
      const result = await fetchData(endpoint, false);

      if (result.success) {
        setCommnets(result.data);
      }
    }
    allCommnts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let handleDelete = async (comment) => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/task/comment/delete/${comment.id}`,
    };
    const result = await deleteComment(endpoint, false);
    if (result.success) {
      setCommnets((prev) => prev.filter((c) => c.id !== comment?.id));
    }
  };
  const onSubmit = async (values, action) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    let endpoint = {
      method: "post",
      url: "/api/admin/task/comment",
      data: formData,
    };
    let result = await addComment(endpoint, false);
    if (result.success) {
      setCommnets((prev) => [...prev, result.data]);
      action.resetForm();
    }
  };
  let { fetchData: realTimeListAPiCall } = useApi();
  useDidMountEffect(() => {
    async function getAllComments() {
      let endpoint = {
        method: "get",
        url: `/api/admin/task/comment/${post?.id}`,
      };

      const result = await realTimeListAPiCall(endpoint, false);
      if (result.success) {
        setCommnets(result.data);
      }
    }
    getAllComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);
  useEffect(() => {
    const pusher = new Pusher(settings?.notification?.pusher_key || "", {
      cluster: settings?.notification?.pusher_cluster || "",
      useTLS: true,
    });

    const channel = pusher?.subscribe("taskComment");
    channel.bind("taskComment", function (data) {
      if (
        (data.action === "create" ||
          data.action === "update" ||
          data.action === "delete") &&
        data.user_id === user_id
      ) {
        return;
      } else {
        setReload((prev) => !prev);
      }
    });
    return () => {
      pusher?.unsubscribe("taskComment");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <CommentForm
        postId={post.id}
        placeholder={"Add Comment"}
        btnText={"Comment"}
        onSubmit={onSubmit}
        loading={addCmntloading}
      />
      <Box sx={{ height: "calc(100vh - 324px)", overflowY: "auto" }}>
        {loading ? (
          <>
            <NoteSkeleton />
            <NoteSkeleton />
            <NoteSkeleton />
          </>
        ) : (
          <>
            {commnets
              .sort((a, b) => new Date(b.created_at) - new Date(a?.created_at))
              .map((comment) => (
                <div key={comment.id}>
                  <Comment
                    setCommnets={setCommnets}
                    comment={comment}
                    handleDelete={handleDelete}
                    editComment={editComment}
                    setEditComment={setEditComment}
                    onSubmit={onSubmit}
                    postId={post?.id}
                  />
                </div>
              ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Comments;
