import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";
import { useState } from "react";
import { BsChevronExpand } from "react-icons/bs";
import Items from "./Items";

const InvoiceTable = ({
  allInvoice,
  loading,
  pagination,
  setPagination,
  setReload,
}) => {
  const [openItems, setOpenItems] = useState(false);
  const rows = allInvoice;
  const columns = [
    { id: "invoice_number", label: "Invoice" },
    { id: "project_name", label: "Project" },
    { id: "due_date", label: "Due Date" },
    { id: "due_amount", label: "Due Amount" },
    { id: "intake_name", label: "Intake" },
    {
      id: "invoice_details",
      label: "Items",
      align: "left",
      style: { width: "200px" },
    },
    { id: "total", label: "Total", align: "right" },
    { id: "status", label: "Status", align: "center" },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 200px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <>
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "date_added" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "due_date" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "due_amount" ? (
                            row.symbol + (row.total - row.paid_amount)
                          ) : column.id === "total" ? (
                            <Typography color="#7171ff" variant="body2">
                              {row.symbol + value}
                            </Typography>
                          ) : column.id === "invoice_details" ? (
                            value?.length ? (
                              <Box
                                sx={{
                                  position: "relative",
                                  cursor: "pointer",
                                  "&:hover": {
                                    "& svg": {
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    },
                                  },
                                  "& svg": {
                                    position: "absolute",
                                    color:
                                      openItems === row?.id
                                        ? (theme) => theme.palette.primary.main
                                        : "#777",
                                    top: "0px",
                                    right: "0px",
                                    cursor: "pointer",
                                    fontSize: "17px",
                                  },
                                }}
                                onClick={() => {
                                  setOpenItems(
                                    openItems === row?.id ? "" : row?.id
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    overflow: "hidden",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                    maxHeight: "22px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {`${value[0]?.fee?.name} ${
                                    value?.length > 1
                                      ? `& ${value?.length - 1}+`
                                      : ""
                                  }`}
                                </Typography>
                                <BsChevronExpand />
                              </Box>
                            ) : (
                              "-"
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={columns?.length}
                    >
                      <Collapse
                        in={openItems === row?.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Box
                            sx={{
                              margin: "0 auto",
                              maxWidth: "650px",
                              zIndex: "-1",
                            }}
                          >
                            <Items
                              items={row.invoice_details}
                              symbol={row.symbol}
                            />
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 200px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default InvoiceTable;
