import TextField from "@mui/material/TextField";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useBase from "hooks/useBase";
import moment from "moment";

const DateTimePicker = (props) => {
  const { base } = useBase();
  const date_format = base?.settings?.app?.date_format;
  const variant = props.variant;
  const formik = props.formik;
  const views = props?.views;
  const inputFormat = props.inputFormat;
  const name = props.name;
  const isComplex = props.isComplex;
  const type = props.type || "date";

  // eslint-disable-next-line no-useless-escape
  const value = !isComplex
    ? formik?.values[name]
    : // eslint-disable-next-line no-useless-escape
      name.split(/[\.\[\]]+/).reduce((acc, key) => {
        if (Array.isArray(acc)) {
          return acc[parseInt(key)];
        }
        return acc[key];
      }, formik.values);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {!views ? (
        type === "date" ? (
          <DatePicker
            {...props}
            value={value}
            onChange={(newValue) => {
              formik.setFieldValue(
                name,
                moment(newValue).isValid()
                  ? moment(newValue).format("YYYY-MM-DD")
                  : null
              );
            }}
            renderInput={(params) => (
              <TextField
                variant={variant || "outlined"}
                {...params}
                size="small"
                error={formik.touched[name] && formik.errors[name]}
                helperText={formik.touched[name] && formik.errors[name]}
              />
            )}
            inputFormat={inputFormat || "DD/MM/YYYY"}
          />
        ) : (
          <TimePicker
            {...props}
            value={moment(value, "HH:mm:ss").toDate()}
            onChange={(newValue) => {
              formik.setFieldValue(
                name,
                moment(newValue).isValid()
                  ? moment(newValue).format("HH:mm:ss")
                  : null
              );
            }}
            renderInput={(params) => (
              <TextField
                variant={variant || "outlined"}
                {...params}
                fullWidth
                size="small"
                error={formik.touched[name] && formik.errors[name]}
                helperText={formik.touched[name] && formik.errors[name]}
              />
            )}
            format="HH:mm:ss"
          />
        )
      ) : (
        <DatePicker
          {...props}
          views={views}
          value={value}
          onChange={(newValue) => {
            formik.setFieldValue(
              name,
              moment(newValue).isValid()
                ? moment(newValue).format("YYYY")
                : null
            );
          }}
          renderInput={(params) => (
            <TextField
              variant={variant || "outlined"}
              {...params}
              size="small"
              error={formik.touched[name] && formik.errors[name]}
              helperText={formik.touched[name] && formik.errors[name]}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
};

export default DateTimePicker;
